require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/home/components/a80e12090eb253d359515d1ae65b1c3e/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'rowsData'))

  },
  /* handler:rowsDataLogic */
  /* handler:onServiceOrderIdCellClick */
  ['onServiceOrderIdCellClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  localStorage.setItem('csgAppData', JSON.stringify(({ [`rowsData`]: (getObjectProperty(___arguments.context.appData, 'rowsData')),[`currentPage`]: (getObjectProperty(___arguments.context.appData, 'currentPage')),[`pageSize`]: (getObjectProperty(___arguments.context.appData, 'pageSize')),[`filters`]: (getObjectProperty(___arguments.context.appData, 'filters')),[`sortOrder`]: (getObjectProperty(___arguments.context.appData, 'sortOrder')),[`sortableColumn`]: (getObjectProperty(___arguments.context.appData, 'sortableColumn')) })));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('orderDetails', ({ [`orderId`]: ___arguments['orderId'] }));

  },
  /* handler:onServiceOrderIdCellClick */
  /* handler:onFiltering */
  async ['onFiltering'](___arguments) {
    var loadedRows;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '') {
    loadedRows = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify(___arguments['filters']),'pageNo': 1,'pageSize': ___arguments['pageSize'] }).send());
  } else {
    loadedRows = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify(___arguments['filters']),'pageNo': 1,'pageSize': ___arguments['pageSize'],'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
  }
  loadedRows['__uid'] = ((new Date()).valueOf());
  ___arguments.context.appData['rowsData'] = loadedRows;

  },
  /* handler:onFiltering */
  /* handler:onSorting */
  async ['onSorting'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments['sortOrder'] == '') {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': 1,'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')) }).send());
  } else {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': 1,'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')),'sortColumn': JSON.stringify(___arguments['column']),'orderByDesc': (___arguments['sortOrder'] == 'desc') }).send());
  }

  },
  /* handler:onSorting */
  /* handler:onSaveSettingsButtonClick */
  async ['onSaveSettingsButtonClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('save');
  await Backendless.Request.post(`${Backendless.appPath}/services/Orders/orders-list-config`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'pageSize': (getObjectProperty(___arguments['params'], 'pageSize')),'columns': (getObjectProperty(___arguments['params'], 'columns')),'isConfigChanged': true });
  ___arguments.context.pageData['columns'] = (getObjectProperty(___arguments['params'], 'columns'));
  ___arguments.context.pageData['pageSize'] = (getObjectProperty(___arguments['params'], 'pageSize'));

  },
  /* handler:onSaveSettingsButtonClick */
  /* handler:onResetToDefaultButtonClick */
  async ['onResetToDefaultButtonClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log('reset');
  await Backendless.Request.post(`${Backendless.appPath}/services/Orders/orders-list-config`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'pageSize': (getObjectProperty(___arguments['params'], 'pageSize')),'columns': (getObjectProperty(___arguments['params'], 'columns')),'isConfigChanged': false });
  ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pageNo': 1,'pageSize': 10 }).send());

  },
  /* handler:onResetToDefaultButtonClick */
  /* handler:columnDefsLogic */
  ['columnDefsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.appData, 'ordersListConfig')) === 'undefined')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'columns'));
  }

  return []

  },
  /* handler:columnDefsLogic */
  /* handler:paginationPageSizeLogic */
  ['paginationPageSizeLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.appData, 'ordersListConfig')) === 'undefined')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'pageSize'));
  }

  },
  /* handler:paginationPageSizeLogic */
  /* handler:isConfigChangedLogic */
  ['isConfigChangedLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.appData, 'ordersListConfig')) === 'undefined')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'isConfigChanged'));
  }

  },
  /* handler:isConfigChangedLogic */
  /* handler:onPageSizeChange */
  async ['onPageSizeChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '') {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': ___arguments['pageSize'] }).send());
  } else {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': ___arguments['pageSize'],'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
  }

  },
  /* handler:onPageSizeChange */
  /* handler:onPagination */
  async ['onPagination'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '') {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': ___arguments['pageSize'] }).send());
  } else {
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': ___arguments['pageSize'],'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
  }
  ___arguments.context.pageData['backdropVisibility'] = false;

  },
  /* handler:onPagination */
  /* handler:paginationPageLogic */
  ['paginationPageLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'currentPage'))

  },
  /* handler:paginationPageLogic */
  /* content */
}))

define('./pages/home/components/de0af1e347e1ccf342ae02b4a6bf779b/bundle.js', [], () => ({
  /* content */
  /* handler:onFilterButtonClick */
  async ['onFilterButtonClick'](___arguments) {
      await ( async function (componentUid, buttons) {  ___arguments.context.getComponentByUid(componentUid).getTopLevelFilterButtons(buttons) })('a80e12090eb253d359515d1ae65b1c3e', ___arguments['buttons']);

  },
  /* handler:onFilterButtonClick */
  /* handler:filteredRowsLengthLogic */
  ['filteredRowsLengthLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.appData, 'topLevelMetricsCounts'))

  },
  /* handler:filteredRowsLengthLogic */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((typeof ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')) === 'undefined') || (((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')) === null)) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (typeof (getObjectProperty(___arguments.context.appData, 'currentUser')) === 'undefined') {
    Backendless.Users.setCurrentUser(((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')), ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')));
    ___arguments.context.appData['currentUser'] = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser'));
  }
  ___arguments.context.pageData['dataGridSpinnerVisibility'] = false;
  if (typeof (getObjectProperty(___arguments.context.appData, 'isHomePageFirstRender')) === 'undefined') {
    ___arguments.context.appData['ordersListConfig'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/orders-list-config`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '' || (typeof (getObjectProperty(___arguments.context.appData, 'sortOrder')) === 'undefined')) {
      ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': 1,'pageSize': (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'pageSize')) }).send());
    } else {
      ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': 1,'pageSize': (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'pageSize')),'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
    }
    ___arguments.context.appData['rowsData'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': 1,'pageSize': (getObjectProperty((getObjectProperty(___arguments.context.appData, 'ordersListConfig')), 'pageSize')),'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))) }).send());
    ___arguments.context.appData['topLevelMetricsCounts'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/top-level-metrics-counts`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    ___arguments.context.appData['currentPage'] = 1;
    ___arguments.context.appData['isHomePageFirstRender'] = true;
  } else {
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/home/components/4f5ea92b59df807d536c2e040bc6ab11/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'dataGridSpinnerVisibility'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/home/components/69ae3e07dc8b22dfcf6f393a043a9887/bundle.js', [], () => ({
  /* content */
  /* handler:onLogout */
  async ['onLogout'](___arguments) {
    var item;


  var item_list = (Object.keys(___arguments.context.appData));
  for (var item_index in item_list) {
    item = item_list[item_index];
    delete ___arguments.context.appData[item];
  }
  await Backendless.UserService.logout();
  localStorage.removeItem('currentUser');
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onLogout */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, email, stayLoggedIn, logging, buttonLogin, x;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( email, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  email = (getObjectProperty(___arguments.context.dataModel, 'email'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (email && password) {
    await updateButtonLogin(true);
    try {
      await login();

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Email and Password must be filled')));
  }
  if (user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    ___arguments.context.appData['currentUser'] = user;
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  ['onEnter'](___arguments) {
      console.log(___arguments.context.appData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/login/components/0bb50a36e11baef8e6ef9ee85ae8b878/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, test;


  try {
    await (async function() {
    	  const { MS_CLIENT_ID, MS_TENANT_ID, MS_REDIRECT_URI } = BackendlessUI.getCustomSettings();

    	  const config = {
    	    auth: {
    	        clientId: MS_CLIENT_ID,
    	        authority: `https://login.microsoftonline.com/${MS_TENANT_ID}`,
    	        redirectUri: MS_REDIRECT_URI,
    	    },
    	  };

    	  const msalInstance = new msal.PublicClientApplication(config);

    	  const loginRequest = {
    	      scopes: ['openid', 'profile', 'offline_access', 'api://8a33e32f-a1aa-4935-8e0e-2024fc62d946/user_impersonation'],
    	  };

    	  const authResult = await msalInstance.loginPopup(loginRequest)

    	  const user = await Backendless.Request.post(`${Backendless.appPath}/services/Auth/auth-with-microsoft`)
    	    .set({Authorization:`Bearer ${authResult.idToken}`})
    	    .send();


    	  if (user) {
    	    localStorage.setItem('currentUser', JSON.stringify(user));
    	    BackendlessUI.Navigator.goToPage("home")
    	  }
    })();

  } catch (error) {
    ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/orderDetails/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var cheatSheet, serviceOrderDetails, agreementsList, orderId, invoicedPrediction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((typeof ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')) === 'undefined') || (((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('currentUser')) === null)) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);
  }
  if (typeof (getObjectProperty(___arguments.context.pageData, 'orderId')) === 'undefined') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
  }
  if (typeof (getObjectProperty(___arguments.context.appData, 'rowsData')) === 'undefined') {
    Object.assign(___arguments.context.appData, ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('csgAppData')));
  }
  orderId = (getObjectProperty(___arguments.context.pageData, 'orderId'));
  try {
    invoicedPrediction = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/invoiced-prediction/${orderId}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  } catch (error) {
    console.log('Error. Get Invoiced Prediction');

  } finally {
    ___arguments.context.pageData['invoicedPrediction'] = invoicedPrediction;

  }
  try {
    serviceOrderDetails = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/order-info/${orderId}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  } catch (error) {
    console.log('Error. Get Order Info ');

  } finally {
    if (getObjectProperty(serviceOrderDetails, 'completion')) {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'completed')),[`billable`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'billable')) });
    } else {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: false,[`billable`]: null });
    }
    ___arguments.context.pageData['serviceOrderDetails'] = serviceOrderDetails;
    ___arguments.context.pageData['orderId'] = orderId;

  }
  try {
    agreementsList = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/agreements-list/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'branchAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());

  } catch (error) {
    console.log('Error. Agreements List');

  } finally {
    ___arguments.context.pageData['agreementsList'] = agreementsList;

  }
  try {
    if (!(typeof (agreementsList[0]) === 'undefined')) {
      cheatSheet = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/cheat-sheet/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'masterAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'agreementTypeId': JSON.stringify((getObjectProperty((agreementsList[0]), 'agreementTypeID'))) }).send());
    } else {
      cheatSheet = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/cheat-sheet/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'masterAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'agreementTypeId': JSON.stringify('1') }).send());
    }

  } catch (error) {
    console.log('Error. Cheat Sheet');

  } finally {
    ___arguments.context.pageData['cheatSheet'] = cheatSheet;

  }
  ___arguments.context.pageData['agreementDetailsSpiner'] = false;

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/orderDetails/components/0efaa6bc7239f3a49535287b2e0c1b3d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')), 'description'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/orderDetails/components/ca788f13b0a1e6f2a704829991c313aa/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/orderDetails/components/c12a31c9564f10b81c3293edf8491c1e/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (typeof (getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')) === 'undefined')

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/orderDetails/components/d9d9f2afbae645e6f5b33a2365bae717/bundle.js', [], () => ({
  /* content */
  /* handler:orderDetailsLogic */
  ['orderDetailsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails'))

  },
  /* handler:orderDetailsLogic */
  /* handler:onVisibilityChange */
  ['onVisibilityChange'](___arguments) {
    
  },
  /* handler:onVisibilityChange */
  /* handler:onFilterClick */
  ['onFilterClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments['filters']);
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(((function () { return window.location })()), 'pathname')), true, (Object.assign(({ [`page`]: 'home' }), ___arguments['filters'])));

  },
  /* handler:onFilterClick */
  /* content */
}))

define('./pages/orderDetails/components/b59326e98ed2a19994adf272eae90a39/bundle.js', [], () => ({
  /* content */
  /* handler:notesListLogic */
  ['notesListLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')), 'notes'))

  },
  /* handler:notesListLogic */
  /* content */
}))

define('./pages/orderDetails/components/105d065249b8482e4a6d443b5a1149a8/bundle.js', [], () => ({
  /* content */
  /* handler:rowsDataLogic */
  ['rowsDataLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')), 'equipment'))

  },
  /* handler:rowsDataLogic */
  /* handler:columnDefsLogic */
  ['columnDefsLogic'](___arguments) {
      return [({ 'field': 'equipmentType','headerName': 'Equipment Type','maxWidth': '300' }), ({ 'field': 'manufacturer','headerName': 'Manufacturer' }), ({ 'field': 'terminalID','headerName': 'Name/Terminal ID','maxWidth': '300' }), ({ 'field': 'onWarranty','headerName': 'On Warranty' })]

  },
  /* handler:columnDefsLogic */
  /* content */
}))

define('./pages/orderDetails/components/0fa38c4629c4d8e1858adaad704fae25/bundle.js', [], () => ({
  /* content */
  /* handler:cheatSheetLogic */
  ['cheatSheetLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'cheatSheet'))

  },
  /* handler:cheatSheetLogic */
  /* handler:onAgreementTypeChange */
  async ['onAgreementTypeChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['cheatSheet'] = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/cheat-sheet/${(getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')), 'orderInfo')), 'masterAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'agreementTypeId': JSON.stringify(___arguments['agreementTypeID']) }).send());

  },
  /* handler:onAgreementTypeChange */
  /* handler:agreementsListLogic */
  ['agreementsListLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'agreementsList'))

  },
  /* handler:agreementsListLogic */
  /* handler:onAgreementIdChange */
  async ['onAgreementIdChange'](___arguments) {
    var agreementOptions;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  agreementOptions = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/options/${___arguments['agreementTypeID']}/${___arguments['agreementID']}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'branchAccountId': JSON.stringify((getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails')), 'orderInfo')), 'branchAccountId'))) }).send());
  agreementOptions['__uid'] = ((new Date()).valueOf());
  ___arguments.context.pageData['agreementOptions'] = agreementOptions;

  },
  /* handler:onAgreementIdChange */
  /* handler:agreementOptionsLogic */
  ['agreementOptionsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(typeof (getObjectProperty(___arguments.context.pageData, 'agreementOptions')) === 'undefined')) {
    return (getObjectProperty(___arguments.context.pageData, 'agreementOptions'));
  } else {
    return [];
  }

  },
  /* handler:agreementOptionsLogic */
  /* content */
}))

define('./pages/orderDetails/components/62b5f29d3bb4dcd7ce097de7de7e64c7/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((typeof (getObjectProperty(___arguments.context.pageData, 'agreementsList')) === 'undefined') || (getObjectProperty(___arguments.context.pageData, 'agreementDetailsSpiner')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/orderDetails/components/89e61a34f9d473bd06d820a5ff623fcf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(typeof (getObjectProperty(___arguments.context.pageData, 'agreementsList')) === 'undefined'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/orderDetails/components/57faa6be4695b09cba844725a352d199/bundle.js', [], () => ({
  /* content */
  /* handler:orderInfoLogic */
  ['orderInfoLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'serviceOrderDetails'))

  },
  /* handler:orderInfoLogic */
  /* handler:onNextButtonClick */
  async ['onNextButtonClick'](___arguments) {
    var cheatSheet, agreementsList, invoicedPrediction, serviceOrderDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    serviceOrderDetails = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/order-info/${___arguments['orderID']}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    invoicedPrediction = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/invoiced-prediction/${___arguments['orderID']}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    agreementsList = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/agreements-list/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'branchAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    cheatSheet = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/cheat-sheet/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'masterAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'agreementTypeId': JSON.stringify('1') }).send());

  } catch (error) {
    console.log('Error');

  } finally {
    if (getObjectProperty(serviceOrderDetails, 'completion')) {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'completed')),[`billable`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'billable')) });
    } else {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: false,[`billable`]: null });
    }
    ___arguments.context.pageData['serviceOrderDetails'] = serviceOrderDetails;
    ___arguments.context.pageData['invoicedPrediction'] = (getObjectProperty(invoicedPrediction, 'invoicedPrediction'));
    ___arguments.context.pageData['agreementsList'] = agreementsList;
    ___arguments.context.pageData['cheatSheet'] = cheatSheet;
    ___arguments.context.pageData['orderId'] = ___arguments['orderID'];
    ___arguments.context.pageData['isNewOrderLoading'] = false;

  }

  },
  /* handler:onNextButtonClick */
  /* handler:onPreviousButtonClick */
  async ['onPreviousButtonClick'](___arguments) {
    var cheatSheet, agreementsList, invoicedPrediction, serviceOrderDetails;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    serviceOrderDetails = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/order-info/${___arguments['orderID']}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    invoicedPrediction = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/order-info/${___arguments['orderID']}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    agreementsList = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/agreements-list/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'branchAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
    cheatSheet = (await Backendless.Request.get(`${Backendless.appPath}/services/Agreements/cheat-sheet/${(getObjectProperty((getObjectProperty(serviceOrderDetails, 'orderInfo')), 'masterAccountId'))}`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'agreementTypeId': JSON.stringify('1') }).send());

  } catch (error) {
    console.log('Error');

  } finally {
    if (getObjectProperty(serviceOrderDetails, 'completion')) {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'completed')),[`billable`]: (getObjectProperty((getObjectProperty(serviceOrderDetails, 'completion')), 'billable')) });
    } else {
      ___arguments.context.pageData['isOrderCompleted'] = ({ [`completed`]: false,[`billable`]: null });
    }
    ___arguments.context.pageData['serviceOrderDetails'] = serviceOrderDetails;
    ___arguments.context.pageData['invoicedPrediction'] = (getObjectProperty(invoicedPrediction, 'invoicedPrediction'));
    ___arguments.context.pageData['agreementsList'] = agreementsList;
    ___arguments.context.pageData['cheatSheet'] = cheatSheet;
    ___arguments.context.pageData['orderId'] = ___arguments['orderID'];
    ___arguments.context.pageData['isNewOrderLoading'] = false;

  }

  },
  /* handler:onPreviousButtonClick */
  /* handler:onNextAndCompleteButtonClick */
  async ['onNextAndCompleteButtonClick'](___arguments) {
      await Backendless.Request.post(`${Backendless.appPath}/services/Orders/complete-order/${___arguments['serviceOrderId']}`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify());

  },
  /* handler:onNextAndCompleteButtonClick */
  /* handler:onLastOrderOfRowsData */
  async ['onLastOrderOfRowsData'](___arguments) {
    var newOrders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '') {
    newOrders = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')) }).send());
  } else {
    newOrders = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')),'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
  }
  if (!(typeof newOrders === 'undefined') && newOrders.length != 0) {
    ___arguments.context.appData['rowsData'] = newOrders;
    ___arguments.context.appData['currentPage'] = ((getObjectProperty(___arguments.context.appData, 'currentPage')) + 1);
  }

  },
  /* handler:onLastOrderOfRowsData */
  /* handler:onLoadOrdersToStart */
  async ['onLoadOrdersToStart'](___arguments) {
    var newOrders;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == '') {
    newOrders = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')) }).send());
  } else {
    newOrders = (await Backendless.Request.get(`${Backendless.appPath}/services/Orders/list`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'filters': JSON.stringify((getObjectProperty(___arguments.context.appData, 'filters'))),'pageNo': ___arguments['currentPage'],'pageSize': (getObjectProperty(___arguments.context.appData, 'pageSize')),'sortColumn': JSON.stringify((getObjectProperty(___arguments.context.appData, 'sortableColumn'))),'orderByDesc': ((getObjectProperty(___arguments.context.appData, 'sortOrder')) == 'desc') }).send());
  }
  if (!(typeof newOrders === 'undefined') && newOrders.length != 0) {
    ___arguments.context.appData['rowsData'] = newOrders;
    ___arguments.context.appData['currentPage'] = ((getObjectProperty(___arguments.context.appData, 'currentPage')) - 1);
  }

  },
  /* handler:onLoadOrdersToStart */
  /* content */
}))

define('./pages/orderDetails/components/824a7b6feed2a04474a27324b3680afb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/redirect/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      ;(async () => {
      await (async function() {
    	document.addEventListener("DOMContentLoaded", function(event) {
    	    const { MS_CLIENT_ID, MS_TENANT_ID, MS_REDIRECT_URI } = BackendlessUI.getCustomSettings();

    	  const config = {
    	    auth: {
    	        clientId: MS_CLIENT_ID,
    	        authority: `https://login.microsoftonline.com/${MS_TENANT_ID}`,
    	        redirectUri: MS_REDIRECT_URI,
    	    },
    	  };

    	  const msalInstance = new msal.PublicClientApplication(config);
    	});
    })();

  })();

  },
  /* handler:onEnter */
  /* content */
}))

